import { Link, navigate, graphql } from 'gatsby'
import React from 'react'
import WordCloud from 'react-d3-cloud'

import Layout from '../../components/en/Layout'
import ArrowIcon from '../../images/icons/Arrow'
import SEO from '../../components/SEO'

const Tags = ({ data }) => {
  let wordCloud = []

  const tags = data.allTagsEn.edges.sort((a, b) =>
    a.node.name.localeCompare(b.node.name)
  )

  const posts = data.allPostsEn.edges

  const getWordCloudWidth = () => {
    if (typeof window === 'undefined') return
    if (window.innerWidth > 1600) {
      return window.innerWidth / 2.4
    } else if (window.innerWidth > 1440) {
      return window.innerWidth / 1.8
    } else if (window.innerWidth > 1366) {
      return window.innerWidth / 1.8
    }
    return window.innerWidth - 50
  }

  const renderCloud = () => {
    if (typeof document === 'undefined') return

    const fontSizeMapper = (word) => Math.log2(word.value) * 10

    return (
      <WordCloud
        width={getWordCloudWidth()}
        fontSizeMapper={fontSizeMapper}
        data={wordCloud}
        padding={2}
        onWordClick={(e) => navigate(`/en/tags/${e.slug}`)}
      />
    )
  }

  const filterPostsByTag = (tag) => {
    let filteredPosts = []

    posts.forEach((post) => {
      post.node.tags.map((t) => {
        if (t.slug === tag.slug) {
          filteredPosts.push(post.node)
        }
      })
    })

    wordCloud.push({
      text: tag.name,
      value: filteredPosts.length,
      slug: tag.slug,
    })

    return <span>{filteredPosts.length} leituras</span>
  }

  return (
    <Layout>
      <SEO
        title="Tags"
        canonical={`${process.env.GATSBY_HOST_URL}/en/tags`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/pt/tags`}
      />

      <div className="tags">
        <h1>Tags</h1>

        <div className="tags-content">
          <div className="tags-cloud">{renderCloud()}</div>

          <h2>Explore</h2>
          <div className="tags-list">
            {tags.map((tag) => (
              <Link to={`/en/tags/${tag.node.slug}`} className="tags-list-item">
                <h3>{tag.node.name}</h3>
                {filterPostsByTag(tag.node)}
                <ArrowIcon />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Tags

export const query = graphql`
  query {
    allTagsEn {
      edges {
        node {
          name
          slug
        }
      }
    }

    allPostsEn {
      edges {
        node {
          name
          slug
          tags {
            name
            slug
          }
        }
      }
    }
  }
`
